import react from 'react';
import '../style/footer.css';

export function Footer() {
    return <footer>
        {/* <h2>fully developed by Charles-André Arsenec</h2> */}

        <h2>
            <span id="footer-span1">fully </span>
            <span id="footer-span2">developed </span>
            <span id="footer-span3">by Charles-André Arsenec</span>
            <span id="footer-span4"> </span>
        </h2>

    </footer>
}