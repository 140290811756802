const computeAge = () => {
    const dateNaissance = new Date(2000, 4, 31);  
    const dateAujourdhui = new Date();
    
    let age = dateAujourdhui.getFullYear() - dateNaissance.getFullYear();
    if (
        dateAujourdhui.getMonth() < dateNaissance.getMonth() ||
        (dateAujourdhui.getMonth() === dateNaissance.getMonth() && dateAujourdhui.getDate() < dateNaissance.getDate())
    ) {
        age--;
    }
    
    return age
}      

export const bios = [
    `At ${computeAge()}, I stand as a French student on the cusp of completing my final year in Computer Science, specializing in AI. A fusion of passion and expertise that's ready to shape the future !`,
    "I have always been driven by two powerful forces: a fervent passion for technology and science, and a profound desire to make a tangible difference in the world. Technological advancements excite me not only for their intrinsic brilliance but also because they provide a canvas upon which I can unleash my creativity. To me, success and excellence are paramount, but they are amplified when used as levers to change the world. My aspiration is clear: to combine my passion for science and technology with my desire to leave a significant mark, creating projects that not only innovate but inspire and shape the future.",
    "My favorite quote, 'However difficult life may seem, there is always something you can do and succeed at,' by Stephen Hawking, resonates deeply within me. It's a beacon of hope amidst life's challenges. Hawking's remarkable achievements, despite his profound health struggles, epitomize the essence of this message – that even in adversity, greatness can be achieved.",
    "Beyond my passion for sciences, history, reading, and cinema, there's a game that holds a special place for me: chess. I've traveled to compete, always admiring the strategy and finesse this ancient game demands. If you ever feel like playing, I'm always up for a match on chess.com. Find me under the username: xx-Gotenk-xx.",
    "Certain books leave a lasting imprint, and for me, 'One Hundred Years of Solitude' by Gabriel García Márquez holds an unrivaled place. This masterpiece of world literature, with its enchanting narratives and masterful weaving of reality and magic, deeply resonated with me. Through the generations of the Buendía family, Márquez offers a poetic exploration of solitude, love, and destiny, painting a poignant picture of the human condition. Every page, every line, stands as a testament to Márquez's unparalleled art of storytelling.",
].sort((a, b) => a.length > b.length)