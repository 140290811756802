import React from "react"

import "../style/project.css"
import { projects } from "../projects_json"
import { chevron_left, chevron_right } from "./utils"
import { useWindowWidth } from "./utils"
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function importAll(r) {
    return r.keys().map(r);
}
  
const images = importAll(require.context('../image/project', false, /\.(png|jpe?g|svg|webp)$/));
  

export function Project(){
    const windowWidth =  useWindowWidth()
    const unique = (arr) => [...new Set(arr)];
    const type = unique(projects.map(project => project.type))
    const location = useLocation();

    React.useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                elem.scrollIntoView();
            }
        } else {
            window.scrollTo({top: 0});
        }
    }, [location])
        

    const projects_class = type.map(type => {
        return  <ProjectClass type={type}/>
    })

    const mobile_projects_class = type.map(type => {
        return  <MobileProjectClass type={type} />
    })


    return  <div id="project">
                <h1> You can see my projects here </h1>
                <p id="project-description">
                    Here are some important projects that have helped me to improve my skills. <br/>
                </p>

                {windowWidth > 900 ? 
                    projects_class : 
                    <>
                        {mobile_projects_class}
                    </>
                }
            </div>
}

function ProjectClass(props){
    const [is_project_hide, hideProject] = React.useState(true)
    const project_object_container = React.useRef(null)
    
    React.useEffect(() => {
        if (is_project_hide){
            project_object_container.current.style.height = "0px"
            project_object_container.current.style.overflow = "hidden"
            project_object_container.current.style.opacity = "0"
        }else{
            project_object_container.current.style.height = "auto"
            project_object_container.current.style.overflow = "auto"
            project_object_container.current.style.opacity = "100%"
            
            const actualHeight = project_object_container.current.scrollHeight;
            setTimeout(() => {
                project_object_container.current.style.height = `${actualHeight}px`;
                project_object_container.current.style.height = "auto"
            }, 10)
        }

    }, [is_project_hide])

    return  <div class="project-class">
        <div class="project-title" onClick={() => hideProject(!is_project_hide)}>
            <h2>{props.type}</h2>
            <div class="project-class-arrow" 
                    style={{transform: is_project_hide ? "rotate(0deg)" : "rotate(90deg)"}}>
                        {chevron_right}
            </div>
        </div>
        <div class="project-object-container" ref={project_object_container}>
            {projects.filter(project => project.type === props.type).map(project => {
                return <ProjectObject
                    image={project.image}
                    title={project.name}
                    description={project.description.replaceAll('\n', "<br/><br/>")}
                    skills={project.skills}
                    authors={project.authors}
                    code={project.code_link}
                    preview={project.preview_link}
                />
                })
            }
        </div>
    </div>
}

function ProjectObject(props){
    const [more, set_more] = React.useState(false)
    const windowWidth =  useWindowWidth()
    const description = React.useRef(null)
    const description_p = React.useRef(null)
    const box_right = React.useRef(null)
    const box_container = React.useRef(null)

    const moreClick = (e) => {
        e.preventDefault()
        set_more(!more)
    }

    const skills = props.skills.map(skill => {
        return <span class="skill">{skill}</span>
    })

    const authors = props.authors.reduce((acc, author) => {
        return acc + ", " + author
    })

    const image = props.image !== "" ? 
        <img src={images.find(path => path.includes(props.image))} /> : 
        <div class="project-object-letter"><span>{props.title[0]}</span></div>

    React.useEffect(() => {
        if (description_p)
            description_p.current.innerHTML = props.description + "authors: " + authors
    }, [])

    React.useEffect(() => {
        const usual_box_height = windowWidth > 900 ? 200 : 400
        const usual_description_height = windowWidth > 900 ? 100 : 200
        const usual_description_p_height = windowWidth > 900 ? 100 : 120

        if(more && description && box_right && box_container){
            description.current.style.height = "auto"
            description_p.current.style.maxHeight = "auto"
            box_right.current.style.height = "auto"
            box_container.current.style.height = "auto"

            const actualHeight = description_p.current.scrollHeight;
            setTimeout(() => {
                description_p.current.style.maxHeight = `${actualHeight}px`;
                if (windowWidth <= 900){
                    box_container.current.style.height = `${actualHeight + box_right.current.style.height + 200}px`
                }else{
                    box_container.current.style.height = `${actualHeight + 100}px`
                }
            }, 10)
        }else if(description && box_right && box_container){
            box_right.current.style.height = "None"
            box_container.current.style.height = `${usual_box_height}px`
            description_p.current.style.maxHeight = `${usual_description_p_height}px`;
            description.current.style.height = `${usual_description_height}px`
            setTimeout(() => {
                description_p.current.style.maxHeight = `${usual_description_p_height}px`;
                description.current.style.height = `${usual_description_height}px`
            }, 10)
        }
    }, [more])

    return <div class="project-object" ref={box_container}>
                <div class="project-object-image">
                    {image}
                </div>
                <div class="project-object-right" ref={box_right}>
                    <div class="project-object-title">
                        <h3>{props.title}</h3>
                        <div class="project-object-skills">
                            {skills}
                        </div>
                    </div>
                    <div class="project-object-description" ref={description}>
                        <p ref={description_p} onClick={moreClick}>{props.description}</p>
                        <div class="project-link">
                            <span onClick={moreClick}>{more ? "less" : "more"}</span>
                            <div class="project-object-links">
                                {props.preview !== "" ? 
                                    <a href={props.preview} target="_blank" class="project-link">
                                        Preview
                                    </a> : ""
                                }
                                {props.code !== "" ? 
                                    <a href={props.code} target="_blank" class="project-link">
                                        Code   
                                    </a> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}

function MobileProjectClass(props){
    return <div class="project-class">
        <div class="project-title">
            <Link to={`/${props.type}#mobile-project-page-title`}>
                <h2>{props.type}</h2>
                <div class="project-class-arrow" >
                    {chevron_right}
                </div>
            </Link>
        </div>
    </div>
}

export function MobileProjectPage(props){
    const location = useLocation();

    React.useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                elem.scrollIntoView();
            }
        } else {
            window.scrollTo({top: 0});
        }
    }, [location])

    return <div class="mobile-project-page">
        <div class="mobile-project-page-title">
            <Link to="/#project"><span>{chevron_left}</span></Link>
            <h1>{props.type}</h1>
        </div>
        
        <div class="mobile-project-object-container">
            {projects.filter(project => project.type === props.type).map(project => {
                return <ProjectObject
                    image={project.image}
                    title={project.name}
                    description={project.description.replaceAll('\n', "<br/><br/>")}
                    skills={project.skills}
                    authors={project.authors}
                    code={project.code_link}
                    preview={project.preview_link}
                />
                })
            }
        </div>
    </div>
}