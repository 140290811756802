import React from "react";
import "../style/presentation.css"
import { useWindowWidth } from "./utils";

import { bios } from "../bio.js"

import m_portrait1 from "../image/portrait/mini/mini-portrait-1.jpg"
import m_portrait2 from "../image/portrait/mini/mini-portrait-2.jpg"
import m_portrait3 from "../image/portrait/mini/mini-portrait-3.jpg"
import m_portrait4 from "../image/portrait/mini/mini-portrait-4.jpg"
import m_portrait5 from "../image/portrait/mini/mini-portrait-5.jpg"

import portrait1 from "../image/portrait/portrait/portrait-1.jpg"
import portrait2 from "../image/portrait/portrait/portrait-2.jpg"
import portrait3 from "../image/portrait/portrait/portrait-3.jpg"
import portrait4 from "../image/portrait/portrait/portrait-4.jpg"
import portrait5 from "../image/portrait/portrait/portrait-5.jpg"

import github from "../image/logo/github.png"
import linkedin from "../image/logo/linkedin.png"
import kaggle from "../image/logo/kaggle.png"

export function Presentation(){
    const window_width = useWindowWidth()

    if (window_width > 900){
        return <div id="presentation">
            <PresentationText/>
            <PresentationImage/>
        </div>
    }else{
        return <>
            <PresentationImage/>
            <PresentationText/>
        </>
    }
}

function PresentationText(){
    const [current_bio, set_bio] = React.useState(bios[0])
    const [index_bio, set_index] = React.useState(0)
    const [last_button, set_last] = React.useState(null)
    const button_refs = React.useRef([])

    const removeLastBackground = () => {
        if (last_button)
            last_button.style.backgroundColor = "transparent"
        else
            button_refs[0].style.backgroundColor = "transparent"
    }

    const handle_choice = (e) => {
        set_bio(bios[e.target.id])
        e.target.style.backgroundColor = "rgb(44, 117, 255)"
        removeLastBackground()
        set_last(e.target)
        set_index((parseInt(e.target.id) + 1) % bios.length)
    }
    const handle_click = (e) => {
        set_index((index_bio + 1) % bios.length)
        set_bio(bios[index_bio])
        button_refs[index_bio].style.backgroundColor = "rgb(44, 117, 255)"
        removeLastBackground()
        set_last(button_refs[index_bio])
    }
    const button_choice = [...Array(bios.length).keys()].map((index) => {
        const color = index == 0 ? 'rgb(44, 117, 255)' : 'none'

        return <button class="lenght-button" id={index} onClick={handle_choice}
                style={{backgroundColor:color}} ref={elm => button_refs[index] = elm}></button>
        })
    
    React.useEffect(() => {}, [])
    console.log(button_refs)
    return <div class="presentation-container" id="presentation-text">
            <div id="presentation-size">
                <span id="what-to-know"> What to know about me </span>

                <div id="lenght-container">
                    <div id="lenght-button-container">
                        {button_choice}
                    </div>
                    
                    <div id="shortest-longest">
                        <span>shortest</span>
                        <span>longest</span>
                    </div>
                </div>
            
            </div>

            <div id="presentation-bio" onClick={handle_click}>
                <p>{current_bio}</p>
            </div>

            <div id="presentation-connect">
                <span id="connect-with">Connect with Me</span>

                <div id="bubble-link-container">
                    <BubbleLink img={github} href={"https://github.com/Ekliipce"}/>
                    <BubbleLink img={linkedin} href={"https://www.linkedin.com/in/charles-andre-arsenec/"}/>
                    <BubbleLink img={kaggle} href={"https://www.kaggle.com/charlesandrarsenec"}/>
                </div>
            </div>
        </div>
}

function BubbleLink(props){
    return <div class="bubble-link" id="linkdin">
        <a href={props.href} target="_blank">
            <img src={props.img}  alt="" srcset=""/>
        </a>
    </div>
}

function PresentationImage(){
    const [image_displayed, set_image] = React.useState(portrait1)
    const [index_image, set_index] = React.useState(0)
    const list_full = [portrait1, portrait2, portrait3, portrait4, portrait5]
    const list_mini = [m_portrait1, m_portrait2, m_portrait3, m_portrait4, m_portrait5]
                        
    const handle_choice = (e) => {
        set_image(list_full[e.target.id])
        set_index(e.target.id)
    }
    const handle_click = (e) => {
        set_index((index_image + 1) % list_full.length)
        set_image(list_full[index_image])
    }


    const img = list_mini.map((e, i) => {
        return <img src={e} class="mini-img" id={i} onClick={handle_choice}/>
    })

    return <div class="presentation-container" id="presentation-photo">
            <div id="portrait">
                <img id="portrait-img" src={image_displayed} onClick={handle_click}/>
                <span><a href={image_displayed}>open full size</a></span>
            </div>

            <div id="portrait-minus">
                {img}
            </div>
    </div>
}