import './style/App.css';

import { Header } from './component/header';
import { Presentation } from './component/presentation';
import { Project } from './component/project';
import { ScrollToTopButton } from './component/utils';
import { Footer } from './component/footer';
import { Routes, Outlet } from 'react-router-dom';

function App() {
  return <>
      <Header/>
      <Presentation/>
      <Project  />
      <Footer/>
      <ScrollToTopButton />
      <Outlet/>
    </>
}

export default App;
