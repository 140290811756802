/*
    Example object
    {
        "name" : "",
        "description" : "",
        "type" : "",
        "date" : "",
        "skills" : [],
        "image" : "",
        "authors" : [],
        "code_link" : "",
        "preview_link" : ""
    }

*/

export const projects = [
        {
            "name" : "Theoretical fundamentals of machine learning",
            "description" : "This project delves deep into the essential theoretical foundations of machine learning. It begins with a detailed exploration of numerical solutions associated with Bayes' risks and empirical risks, highlighting the differences and similarities between them.\n It continues with a regression exercise, wherein a dataset is generated based on predefined parametric laws, thus revealing the known empirical risk. The goal here is to unearth the most effective model to approximate this predetermined risk. In a similar vein, the project introduces a classification exercise. \n \
            The concluding stages venture into practical applications: a supervised task focused on predicting house prices, and an unsupervised exploration zeroing in on the identification of shared features amongst celebrity faces. Each exercise is tailored to reinforce understanding and application of core machine learning principles. \n",
            "type" : "AI, Data science, Machine Learning and Deep Learning",
            "date" : "2013-01-01",
            "skills" : ["Machine Learning"],
            "image" : "machine-learning-theory-project",
            "authors" : ["François Soulier", "Bastien Hoorelbeke", "Raphael Bennaim", "Charles-André Arsenec"],
            "code_link" : "https://github.com/Ekliipce/Machine-Learning-Theory-Project",
            "preview_link" : ""
        }, 
        {
            "name" : "Natural Language Processing",
            "description" : "This is a project",
            "type" : "AI, Data science, Machine Learning and Deep Learning",
            "date" : "2013-01-01",
            "skills" : ["Machine Learning", "Deep Learning"],
            "image" : "nlp",
            "authors" : ["Hugo Deplagne", "Pierre Litoux", "Charles-André Arsenec"],
            "code_link" : "https://github.com/Coartix/NLP",
            "preview_link" : ""
        },
        {
            "name" : "Bitcoin 2015-2017 Analysis",
            "description" : "The EGC 2024 Challenge tasked us with examining Bitcoin's intricate data landscape. Initially, our study probed the relationship between the total transaction fees and the number of transactions, seeking patterns suggesting whether higher activity corresponds to greater fees. Next, by incorporating Bitcoin's price data, we delved deeper to understand the symbiotic relationship between transaction volumes and Bitcoin's price fluctuations. Lastly, in the wake of China's 2017 ban on Bitcoin exchanges, our focus turned to the Chinese market. We aimed to trace the evolution of Chinese Bitcoin actors, identifying platforms that bore the brunt of this pivotal regulatory shift. \n",
            "type" : "AI, Data science, Machine Learning and Deep Learning",
            "date" : "2013-01-01",
            "skills" : ["Data science"],
            "image" : "bitcoin-analysis",
            "authors" : [ "Raphael Bennaim", "Charles-André Arsenec", ],
            "code_link" : "https://github.com/Ekliipce/2015-2017-Bitcoin-analysis",
            "preview_link" : ""
        },
        {
            "name" : "Recommender system: Restaurant orders",
            "description" : "In our endeavor to devise a recommendation engine, our primary focus was on predicting restaurants customers might favor based on their location, the intricate details of restaurants, and their past order history. We started by creating a user-vendor matrix that used order ratings to represent user preferences for different vendors. This matrix then became the foundation upon which we trained our model, using collaborative filtering techniques like ALS (Alternating Least Squares) or Funck SVD, to forecast user ratings for the various vendors. Once trained, this model was deployed to make predictions on a test set. Our final recommendations were then fine-tuned by taking into account both the predicted ratings and the capacity of the vendors to deliver to the user's location, ensuring a blend of preference and practicality in our suggestions. \n",
            "type" : "AI, Data science, Machine Learning and Deep Learning",
            "date" : "2013-01-01",
            "skills" : ["Machine Learning"],
            "image" : "restaurant",
            "authors" : ["Charles-André Arsenec"],
            "code_link" : "https://github.com/Ekliipce/Recommender-System---Final-Project",
            "preview_link" : ""
        },        {
            "name" : "Multi-agent system : Peppered moth during the industrial revolution",
            "description" : "In our multi-agent system project, we ventured into the exploration of genetic distribution factors throughout evolution. Specifically, our investigation set out to replicate an existing species case through a model. The focal point of this model is the evolutionary trajectory of the birch moths (nocturnal butterflies). Our objective is to discern how the pollution during the industrial revolution has influenced certain butterfly species. This simulation seeks to analyze the ramifications of industrial pollution on the birch moth population, with a keen interest in the intricacies of mimicry and natural selection phenomena.\n\
            In our multi-agent system project, we ventured into the exploration of genetic distribution factors throughout evolution. Specifically, our investigation set out to replicate an existing species case through a model. The focal point of this model is the evolutionary trajectory of the birch moths (nocturnal butterflies). Our objective is to discern how the pollution during the industrial revolution has influenced certain butterfly species. This simulation seeks to analyze the ramifications of industrial pollution on the birch moth population, with a keen interest in the intricacies of mimicry and natural selection phenomena.\n\
            To encapsulate these dynamics within our simulation, we considered several parameters:<br>\
            - The phenotypic proportion of the moths, which allows us to also control the number of predators. Additionally, we incorporated graphs to track population changes over time.<br>\
            - The predation rate is crucial. It signifies the likelihood of a moth being consumed by a predator when they are in proximity. We were particularly interested in emulating the mimicry phenomenon. The degree of mimicry is calculated based on the resemblance between the moth's color and its environment. Consequently, the predation rate is inversely related to the level of mimicry. Thus, a moth adept in mimicry has a lower probability of being preyed upon.<br>\
            - Pollution plays a pivotal role. It modifies the environmental color, subsequently influencing individual mimicry and predation rates. \n",
            "type" : "AI, Data science, Machine Learning and Deep Learning",
            "date" : "2013-01-01",
            "skills" : ["AI"],
            "image" : "peppered moth",
            "authors" : ["François Soulier", "Bastien Hoorelbeke", "Raphael Bennaim", "Charles-André Arsenec"],
            "code_link" : "",
            "preview_link" : ""
        },        {
            "name" : "Convex analysis of mathematical programming",
            "description" : "Our project delves into the convergence of various gradient descent methods in mathematical programming. We started with the foundational Constant Step Gradient Descent, followed by the adaptive Optimal Step Gradient Descent which adjusts the step size for efficient convergence. The L1 Gradient Descent brings in the aspect of L1 regularization, paving the way for advanced techniques like Fletcher-Reeves and Polak-Ribiére, both conjugate gradient methods. The BFGS Method, a quasi-Newton approach, optimizes convergence even further. Lastly, to enhance the optimization process, we incorporated Nesterov's Accelerated Gradient and the Momentum Method, both of which accelerate convergence by refining the gradient descent mechanism. \n",
            "type" : "AI, Data science, Machine Learning and Deep Learning",
            "date" : "2013-01-01",
            "skills" : ["Mathematics"],
            "image" : "convex analysis",
            "authors" : ["Amin El-maghraoui", 'Param Dave', "Charles-André Arsenec"],
            "code_link" : "",
            "preview_link" : ""
        },        {
            "name" : "Navires 2023 Compétitions",
            "description" : "This project centers around a deep learning competition aimed at classifying boat images based on their distinct categories. The main challenge was the quality of the images, notably of low resolution (8x8 pixels), making their identification by the naked eye nearly impossible. The competition was bifurcated into two challenges: the first required the development of an entirely new model, while the second emphasized a transfer learning approach, leveraging a pre-existing model to adapt and specialize. In collaboration with my partner, we employed various strategies to refine our convolutional model. Our approach was rooted in theoretical enhancements, but we primarily focused on an empirical methodology, adjusting our model based on observations of validation and training losses. This iterative trial-and-error method, steered by result interpretation, was pivotal to our strategy. \n",
            "type" : "AI, Data science, Machine Learning and Deep Learning",
            "date" : "2013-01-01",
            "skills" : ["Deep Learning"],
            "image" : "navires 2023",
            "authors" : ["Habib Adoum-Mandazou", "Charles-André Arsenec"],
            "code_link" : "",
            "preview_link" : ""
        },{
            "name" : "Data Engeneering Introduction: Harmony State",
            "description" : "Set in the utopian realm of Harmonyland, a land governed by a compassionate leader intent on maintaining peace, happiness, and harmony among its populace, our project takes inspiration from the harmonymakers. This government agency is on a quest to infuse the nation with harmony by aiding any troubled souls in regaining their sense of peace and alignment with the nation's harmonious objectives. \n \
            The core of our project revolves around the implementation of a foundational data engineering architecture known as the 'hot path-cold path' setup. We utilize the 'cold path' for batch processing of data, subsequently storing it in a DataLake, leveraging AWS's cloud storage capabilities. Concurrently, real-time data management is facilitated via Spark in the 'hot path.' Should a citizen's harmony score dip below an acceptable threshold, an alert is dispatched via Discord, signaling the potential need for the harmonymakers' intervention. This fusion of fictional narrative with practical data engineering methodologies underscores the importance of monitoring and maintaining societal harmony using advanced tech solutions. \n",
            "type" : "AI, Data science, Machine Learning and Deep Learning",
            "date" : "2013-01-01",
            "skills" : ["Data engeneering"],
            "image" : "harmony state",
            "authors" : ["François Soulier", "Raphael Bennaim", "Charles-André Arsenec"],
            "code_link" : "https://github.com/sinansoulier/HarmonyState",
            "preview_link" : ""
        },{
            "name" : "Sudoku Solver",
            "description" : "The project delves into the fascinating realm of solving Sudoku puzzles, harnessing the power of Z3, a renowned SAT solver.\n \
            Z3 is a high-performance theorem prover developed by Microsoft Research. It's an algorithm that's designed to check the satisfiability of logical formulas. While originally designed for software verification, Z3 has found utility in a wide range of applications, including the solving of Sudoku puzzles.\n \
            In the context of Sudoku, each cell in the puzzle can be considered a variable, and the rules of Sudoku impose constraints on these variables. For instance, a number can appear only once in a given row, column, or 3x3 square. By representing a Sudoku puzzle as a series of constraints, Z3 can rapidly deduce the values for each cell that satisfy all given constraints, effectively solving the puzzle. This project showcases not only the versatility of SAT solvers like Z3 but also demonstrates a novel application in a popular recreational domain. \n",
            "type" : "AI, Data science, Machine Learning and Deep Learning",
            "date" : "2013-01-01",
            "skills" : ["AI"],
            "image" : "sudoku",
            "authors" : ["Bastien Hoorelbeke", "Raphael Bennaim", "Charles-André Arsenec"],
            "code_link" : "https://github.com/Bast-94/2023-EPITA-SCIA-PPC-Sudoku-CV/tree/bastien",
            "preview_link" : ""
        },{
            "name" : "Video Processing",
            "description" : "In the evolving domain of video processing, the project at hand specifically addresses the intricate procedure of motion estimation. Motion estimation seeks to understand how and where objects shift from one frame to another in a video sequence. Delving into its mathematical facets, the project introduces the concept of 'edfd'. EDFD, or 'Edge-Directed Frame Difference', is a method that focuses on the variations between two consecutive frames, emphasizing the edges. Recognizing the edges is crucial because our eyes are particularly sensitive to boundaries and changes, making the edge-directed methods more aligned with human visual perception. \n \
            Subsequently, the project touches upon motion compensation. This is a pivotal step in video compression, as it's used to predict a frame in a video sequence based on the previous frames. Essentially, after identifying the motion of objects between frames (via motion estimation), motion compensation produces a predicted frame, and only the difference between this predicted frame and the actual frame (residual) is encoded. This reduces the amount of data that needs to be stored or transmitted, leading to more efficient compression. \n \
            The future trajectory of this endeavor will delve into leveraging artificial intelligence for video compression. As neural networks and machine learning models have demonstrated remarkable capabilities in image and video processing tasks, integrating AI could pave the way for more advanced, efficient, and adaptive compression methods, tailoring the process to specific video content or transmission conditions. \n",
            "type" : "AI, Data science, Machine Learning and Deep Learning",
            "date" : "2013-01-01",
            "skills" : ["Image Processing, AI"],
            "image" : "video processing",
            "authors" : ["Charles-André Arsenec"],
            "code_link" : "https://github.com/Ekliipce/Motion-Estimation-",
            "preview_link" : ""
        },{
            "name" : "Ecommerce Audiophile Website",
            "description" : "This project represents an ecommerce platform built primarily using React. Specifically designed to cater to audiophiles, the site is a haven for enthusiasts of sound equipment, featuring products ranging from headphones to speakers. \n \
            The complexity of this project stands out in comparison to other frontend undertakings I've embarked upon. Multiple factors contributed to this heightened challenge. First and foremost, the sheer number of pages required meticulous management, ensuring each page not only functioned optimally but also provided an intuitive user experience. Adding another layer of intricacy was the comprehensive responsive design. Unlike standard designs that focus predominantly on mobile and desktop views, this site was fine-tuned to accommodate tablet interfaces, ensuring a seamless browsing experience across all device types. \n",
            "type" : "Website, Front-end and Back-end",
            "date" : "2013-01-01",
            "skills" : ["React"],
            "image" : "audiophile-website",
            "authors" : ["Charles-André Arsenec"],
            "code_link" : "https://github.com/Ekliipce/ecommerce-audiphile-website",
            "preview_link" : "https://ecommerce-audiphile-website-m8cnavpc5-ekliipce.vercel.app/"
        },{
            "name" : "Country API",
            "description" : "This project served as my introductory experience with React. Designed as an informational tool, the platform provides users with detailed insights about countries from all corners of the world. The essence of the project lies in its simplicity and functionality, allowing users to efficiently search and learn about different nations. \n \
            A primary feature of the website is its straightforward search functionality. Users have the option to search for countries by name or by their geographical location, ensuring ease of access to the information they seek. The platform's efficiency is largely attributed to an integrated API that fetches relevant data for each country in real-time. \n",
            "type" : "Website, Front-end and Back-end",
            "date" : "2013-01-01",
            "skills" : ["React", "API"],
            "image" : "country api",
            "authors" : ["Charles-André Arsenec"],
            "code_link" : "https://github.com/Ekliipce/Country-API",
            "preview_link" : "https://country-ol2vf58lz-ekliipce.vercel.app/"
        },{
            "name" : "Planet Fact",
            "description" : "Planet Fact website offers an intriguing dive into the mysteries of our solar system. It's a digital space where users can immerse themselves in various details and intriguing tidbits about each planet. Given a foundational understanding of CSS and React, assembling this project was both enjoyable and straightforward. Crafting the mobile versions also proved to be a seamless endeavor. \n \
            While the current iteration of the project presents planets in a static format, there's potential for growth. Envisioning the future, the incorporation of ThreeJS could breathe life into these celestial bodies, rendering them in rich, interactive 3D. Such an enhancement would not only elevate the user experience but would also be a testament to the continuous journey of improving and expanding one's technical skill set. \n",
            "type" : "Website, Front-end and Back-end",
            "date" : "2013-01-01",
            "skills" : ["React"],
            "image" : "planet fact",
            "authors" : ["Charles-André Arsenec"],
            "code_link" : "https://github.com/Ekliipce/planet-fact",
            "preview_link" : "https://planet-fact-6y9sjj4xd-ekliipce.vercel.app/"
        },{
            "name" : "Todo App",
            "description" : "The Todo App is a straightforward implementation I crafted using JS, HTML, and CSS, based on the designs provided to me. The application allows users to add tasks, mark them off once completed, and delete them when no longer needed. A simple yet user-friendly feature I incorporated was a dark mode, which was an exciting first-time endeavor for me. Currently, the app lacks a persistent storage mechanism, meaning tasks aren't saved if the page is closed or refreshed. Considering this, adding a storage solution could be a beneficial enhancement for any future updates. \n",
            "type" : "Website, Front-end and Back-end",
            "date" : "2013-01-01",
            "skills" : ["CSS", "Javascript"],
            "image" : "todo-app",
            "authors" : ["Charles-André Arsenec"],
            "code_link" : "https://github.com/Ekliipce/Todo-app",
            "preview_link" : "https://todo-app-gamma-ten.vercel.app/"
        },{
            "name" : "Lauch Countdown Timer",
            "description" : "It is a minimalistic frontend project that displays a countdown to a set event or launch. Designed with simplicity in mind, it provides users with a clear visual representation of days, hours, minutes, and seconds left until the specified time. This kind of tool can be particularly useful for product launches, event promotions, or any other significant occasion that users might be anticipating. \n",
            "type" : "Website, Front-end and Back-end",
            "date" : "2013-01-01",
            "skills" : ["CSS", "Javascript"],
            "image" : "countdown timer",
            "authors" : ["Charles-André Arsenec"],
            "code_link" : "https://github.com/Ekliipce/Launch-countdown-timer",
            "preview_link" : "https://launch-countdown-timer-mauve.vercel.app/"
        },{
            "name" : "Interactive Pricing Component",
            "description" : "Interactive Pricing Component is a frontend challenge that provides an engaging user interface for pricing selection. This project showcases an interactive pricing slider complemented by a custom range input, allowing users to adjust and visualize their chosen price point dynamically. Additionally, it incorporates a pricing toggle for added interactivity. It serves as an excellent exercise to hone and demonstrate JavaScript proficiency, merging functionality with an intuitive user experience. \n",
            "type" : "Website, Front-end and Back-end",
            "date" : "2013-01-01",
            "skills" : ["CSS", "Javascript"],
            "image" : "pricing",
            "authors" : ["Charles-André Arsenec"],
            "code_link" : "https://github.com/Ekliipce/integration-challenge-Interactive-pricing-component",
            "preview_link" : "https://integration-challenge-interactive-pricing-component-laql8g6ik.vercel.app/"
        },{
            "name" : "Posix Shell creation",
            "description" : "42sh is a famous project in my school, representing a deep dive into the world of operating systems and shell programming. It involves the creation of a fully functional Posix Shell, leveraging synthetic analysis to interpret and execute commands. The endeavor challenges one's understanding of system calls, process management, and other core concepts pivotal to shell design. Such a project not only provides a hands-on experience with low-level programming but also offers an appreciation of the intricacies behind the command-line interfaces we use daily. \n",
            "type" : "Software Engineering",
            "date" : "2013-01-01",
            "skills" : ["C", "Language Theory"],
            "image" : "42sh",
            "authors" : ["Benjamin Guichard", "Jean-Sébastien Kersaint Tournebize", "Théo Bonzi", "Charles-André Arsenec"],
            "code_link" : "",
            "preview_link" : ""
        },{
            "name" : "Java API Rest : Bomberman",
            "description" : "This project is a dive into the backend world, focusing on creating a RESTful API to power the iconic game. This API is responsible for managing game logic, player interactions, and tracking the game's state. It seamlessly integrates with a database to store game sessions, player statistics, and other essential game data. Through this project, the complexities of game development meet the challenges of backend engineering, offering a holistic approach to building a comprehensive gaming experience. \n",
            "type" : "Software Engineering",
            "date" : "2013-01-01",
            "skills" : ["Java"],
            "image" : "bomberman",
            "authors" : ["Charles-André Arsenec"],
            "code_link" : "",
            "preview_link" : ""
        },{
            "name" : "C librairies reimplementation",
            "description" : "C librairies reimplementation \n",
            "type" : "Software Engineering",
            "date" : "2013-01-01",
            "skills" : ["C"],
            "image" : "",
            "authors" : ["Charles-André Arsenec"],
            "code_link" : "",
            "preview_link" : ""
        },{
            "name" : "C++ librairies reimplementation",
            "description" : "C++ librairies reimplementation \n",
            "type" : "Software Engineering",
            "date" : "2013-01-01",
            "skills" : ["C", "C++"],
            "image" : "",
            "authors" : ["Charles-André Arsenec"],
            "code_link" : "",
            "preview_link" : ""
        }
    ]