import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./style/index.css"
import { projects } from "./projects_json"
import { MobileProjectPage } from "./component/project"
import { BrowserRouter, Routes, Route, useLocation} from "react-router-dom";

const unique = (arr) => [...new Set(arr)];
const type = unique(projects.map(project => project.type))

const root = ReactDOM.createRoot(document.getElementById('root'));

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    console.log("ScrollToTop is called for", pathname);
    setTimeout(window.scrollTo(0, 0), 100);
  }, [pathname]);

  return null;
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App/>}/>
        {type.map((type) => (
                    <Route path={`/${type}`} element={<MobileProjectPage type={type} />} />
        ))}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
