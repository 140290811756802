import React, {useEffect, useState} from "react";
import { useWindowWidth, menu } from "./utils";
import "../style/header.css"

import pp from "../image/ch.png"

export function Header(){
    return <div id="header">
      <Navbar/>
      <Identity/>
    </div>
}

function DropdownMenu() {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const dropdown_content = React.useRef(null);

  // Toggle the dropdown
  const toggleOpen = () => {
      if (dropdown_content.current.style.display !== "block"){
        dropdown_content.current.style.display = "block"
      }else{
        dropdown_content.current.style.display = "none"
      }
  }

  return (
      <ul className="dropdown" ref={dropdownRef}>
        <div onClick={toggleOpen} className="dropbtn">{menu}</div>
        <div className="dropdown-content" ref={dropdown_content} onClick={toggleOpen}>
          <li><a href="#presentation-text">About me</a> </li>
          <li><a href="#project">Projects</a></li>
          <li><a href="/Charles-André_Arsenec-CV.pdf" target="_blank" rel="noopener noreferrer">Resume</a></li>
          <li><a href="https://www.linkedin.com/in/charles-andre-arsenec/" target="_blank">Contact me</a></li>
        </div>
        
      </ul>
  );
}


export function Navbar(){   
    const window_width = useWindowWidth()

    const nav_ul = <ul>
        <li><a href="#presentation-text">About me</a> </li>
        <li><a href="#project">Project</a></li>
        <li><a href="../download/Charles-André_Arsenec-CV" target="_blank">Resume</a></li>
        <li><a href="https://www.linkedin.com/in/charles-andre-arsenec/" target="_blank">Contact me</a></li>
    </ul>

    return(
        <nav>
            <div id="pp"><img src={pp} alt=""/></div>

            {window_width > 900 ? nav_ul : <DropdownMenu/>}
        </nav>
    )
}

export function Identity(){
  const identity = [
    "is your new engineer",
    "is a builder app",
    "wants to work in ia",
    "is a buisness creator",
    "is a self investor"
  ]

  const [title_index, set_index]  = useState(0)
  const handle_click = () => {
      set_index((title_index + 1) % identity.length)
  }

  return  <div id="identity">
    <div id="identity-center" onClick={handle_click}>
        <h1 id="identity-name" >Charlie</h1>
        <h2 id="identity-fname" class="change">Arsenec</h2>
        <h4 id="identity-title" class="change">{identity[title_index]}</h4>
        
        <button id="change-identity" ><svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086
            8 12C8 14.2091 9.79086 16 12 16ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 
            8.68629 18 12 18Z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M11 0H13V4.06189C12.6724 4.02104 12.3387
              4 12 4C11.6613 4 11.3276 4.02104 11 4.06189V0ZM7.0943 5.68018L4.22173 2.80761L2.80752 4.22183L5.6801 7.09441C6.09071 6.56618
              6.56608 6.0908 7.0943 5.68018ZM4.06189 11H0V13H4.06189C4.02104 12.6724 4 12.3387 4 12C4 11.6613 4.02104 11.3276 4.06189
                11ZM5.6801 16.9056L2.80751 19.7782L4.22173 21.1924L7.0943 18.3198C6.56608 17.9092 6.09071 17.4338 5.6801 16.9056ZM11
                19.9381V24H13V19.9381C12.6724 19.979 12.3387 20 12 20C11.6613 20 11.3276 19.979 11 19.9381ZM16.9056 18.3199L19.7781
                  21.1924L21.1923 19.7782L18.3198 16.9057C17.9092 17.4339 17.4338 17.9093 16.9056 18.3199ZM19.9381 13H24V11H19.9381C19.979
                  11.3276 20 11.6613 20 12C20 12.3387 19.979 12.6724 19.9381 13ZM18.3198 7.0943L21.1923 4.22183L19.7781 2.80762L16.9056
                    5.6801C17.4338 6.09071 17.9092 6.56608 18.3198 7.0943Z" fill="currentColor" /></svg></button>
    </div>
  </div>
}